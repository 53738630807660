import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
// import material ui
import Typography from "@material-ui/core/Typography"
// import Button from "@material-ui/core/Button"
// import Badge from "@material-ui/core/Badge"
import useMenuItems from "./useMenuItems"

// import app components
import { useStore } from "../../store"
import * as theme from "../../theme"

const MobileMenu = (props) => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()
  const items = useMenuItems("desktop-main-menu")

  return (
    <Menu {...props} menuState={menu}>
      <ExternalLink
        onClick={() => dispatch({ type: "SET_MENU", payload: false })}
        href="https://vwmc.ca/user/login"
        className="link-item"
        target="_blank"
      >
        <Title variant="subtitle1" children="Member Login" />
      </ExternalLink>
      <div>
        {items &&
          items.map(({ id, url, label, childItems }, index) => (
            <React.Fragment key={url + index}>
              {childItems && childItems.nodes.length ? (
                <>
                  <Item
                    onClick={() =>
                      dispatch({ type: "SET_MENU", payload: false })
                    }
                    to={url}
                    className="parent-item"
                  >
                    <Title variant="subtitle1" children={Parser(label)} />
                  </Item>
                  {childItems.nodes.map((item, index) => (
                    <Item
                      key={item.url + index}
                      onClick={() =>
                        dispatch({ type: "SET_MENU", payload: false })
                      }
                      className="child-item"
                      to={item.url}
                    >
                      <Title
                        variant="subtitle1"
                        children={Parser(item.label)}
                      />
                    </Item>
                  ))}
                </>
              ) : (
                <Item
                  onClick={() => dispatch({ type: "SET_MENU", payload: false })}
                  key={url + index}
                  to={url}
                  className="link-item"
                >
                  <Title variant="subtitle1" children={Parser(label)} />
                </Item>
              )}
            </React.Fragment>
          ))}
      </div>
    </Menu>
  )
}

export default MobileMenu

const menuClose = css`
  transform: translateX(120%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateX(0);
  opacity: 1;
`

const Menu = styled.div`
  ${(props) => (!!props.menuState ? menuOpen : menuClose)}
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 101;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: ${theme.colors.primary};
  padding-top: 69px;
  overflow: scroll;
  .link-item {
    background: #26384d;
  }
  .parent-item {
    background: #26384d;
  }
`

const ItemCSS = css`
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  width: 100%;
  text-align: center;
  &:hover {
    background: #385372;
  }
`
const ExternalLink = styled.a`
  ${ItemCSS}
`
const Item = styled(Link)`
  ${ItemCSS}
`

// const NonLinkItem = styled.div`
//   ${ItemCSS}
//   margin-top: 40px;
//   cursor: pointer;
// `

const Title = styled(Typography)`
  && {
    padding: 5px 0;
    color: white;
    text-transform: uppercase;
    font-size: 18px;
  }
`

// const LoginButton = styled(Button)`
//   && {
//     padding: 2px 34px;
//     font-size: 12px;
//   }
// `

// const StyledBadge = styled(Badge)`
//   > span {
//     right: -14px;
//     top: 10px;
//     height: 16px;
//     font-size: 11px;
//     width: 16px;
//     min-width: unset;
//     padding: 0;
//     background: white;
//     color: ${theme.colors.primary};
//   }
// `
