export const setAuth = data => {
  const { user, authToken, refreshToken } = data

  let is_token_expired = true
  const token_expiry = user.jwtAuthExpiration

  const jwtExpiration = token_expiry ? parseInt(token_expiry) : false
  let currentTimeInSeconds

  if (jwtExpiration) {
    currentTimeInSeconds = Math.floor(Date.now() / 1000)
    is_token_expired = jwtExpiration <= currentTimeInSeconds
  }

  if (!is_token_expired) {
    localStorage.setItem(
      "starter.data",
      JSON.stringify({
        token_expiry,
        token: authToken,
        uniqueId: user.id,
        refresh_token: refreshToken
      })
    )

    return {
      token: authToken,
      token_expiry,
      refresh_token: refreshToken,
      is_token_expired,
      uniqueId: user.id
    }
  } else {
    return {
      token: null,
      token_expiry: null,
      refresh_token: refreshToken,
      is_token_expired,
      uniqueId: null
    }
  }
}
