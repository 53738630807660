export const getAuth = () => {
  if (
    typeof localStorage === "undefined" ||
    !localStorage.getItem("starter.data")
  )
    return {}

  const { token, token_expiry, refresh_token, uniqueId } = JSON.parse(
    localStorage.getItem("starter.data")
  )

  let is_token_expired = true
  const jwtExpiration = token_expiry ? parseInt(token_expiry) : false

  if (jwtExpiration) {
    const currentTimeInSeconds = Math.floor(Date.now() / 1000)

    is_token_expired = jwtExpiration <= currentTimeInSeconds
  }

  const isAuthed = is_token_expired || !token ? false : true

  return {
    token,
    token_expiry,
    refresh_token,
    is_token_expired,
    isAuthed,
    uniqueId
  }
}
