import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

const defaultTheme = createTheme()
const { breakpoints } = defaultTheme

export default createTheme({
  typography: {
    fontFamily: "DM Sans",
    h1: {
      fontFamily: "Amiri",
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "28px",
      marginTop: 0,
      [breakpoints.up("md")]: {
        fontSize: "38px",
        lineHeight: "35px"
      },
      [breakpoints.up("lg")]: {
        fontSize: "48px",
        lineHeight: "45px"
      }
    },
    h2: {
      fontFamily: "Amiri",
      fontSize: "36px",
      lineHeight: "36px",
      fontWeight: 700,
      marginTop: 0,
      color: colors.secondary
    },
    h3: {
      fontFamily: "Amiri",
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: 700,
      marginTop: 0
    },
    h4: {
      fontSize: "21px",
      lineHeight: "26px",
      marginTop: 0
    },
    h5: {
      fontFamily: "Amiri",
      fontSize: "18px",
      lineHeight: "31px",
      fontWeight: 700,
      letterSpacing: "0.5px",
      marginTop: 0
    },
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "normal",
      color: colors.pickledBluewood,
      [breakpoints.up("md")]: {
        fontSize: "19px",
        lineHeight: "1.4"
      }
    },
    body2: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "normal"
    },
    caption: {
      textTransform: "uppercase",
      fontSize: "14px",
      letterSpacing: "2px"
    },
    button: {
      fontFamily: "Amiri",
      fontSize: "15px",
      lineHeight: "18px",
      fontWeight: 700,
      letterSpacing: "0.5px"
    }
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiButton: {
      root: {
        border: "1px solid " + colors.secondary,
        borderRadius: "unset",
        "&:hover": {
          backgroundColor: colors.secondary,
          color: "white"
        }
      },
      text: {
        padding: "9px 8px 6px"
      }
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: "0.81rem"
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.9)"
      }
    }
  }
})
