import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled, { css } from "styled-components"

const isUrlExternal = url => {
  return !!url.startsWith("http")
}

const Link = ({ to, target, ...rest }) => {
  if (to && isUrlExternal(to)) {
    return <ExternalLink href={to} target="_blank" rel="noopener" {...rest} />
  } else {
    return <InternalLink to={to} {...rest} />
  }
}

export default Link

const linkStyle = css`
  text-decoration: none;
  color: inherit;
`
const InternalLink = styled(GatsbyLink)`
  ${linkStyle}
`
const ExternalLink = styled.a`
  ${linkStyle}
`
