import React from "react"
import styled from "styled-components"

// import material ui
import Typography from "@material-ui/core/Typography"
// import Grid from "@material-ui/core/Grid"

// import app components
import Edges from "../edges"
import SocialIcons from "./SocialIcons"
import FooterMenuItems from "../menu/FooterMenu"
import useFooterQuery from "./useFooterQuery"
import Link from "../link"

import Logo from "../../images/logo-glyph.svg"

import CallIcon from "react-ionicons/lib/IosCall"

export default () => {
  const {
    themeOptions: {
      siteOptions: {
        socials,
        addressLine1,
        addressLine2,
        postalCode,
        phoneNumber
      }
    }
  } = useFooterQuery()

  return (
    <Footer>
      <Edges size="lg">
        <Flex>
          <Menu>
            <FooterMenuItems />
          </Menu>

          <Address>
            {!!addressLine1 && (
              <Typography color="inherit">{addressLine1}</Typography>
            )}
            {!!addressLine2 && (
              <Typography color="inherit">{addressLine2}</Typography>
            )}
            {!!postalCode && (
              <Typography color="inherit">{postalCode}</Typography>
            )}
            {!!phoneNumber && (
              <PhoneLink href={phoneNumber.url}>
                <CallIcon />
                <Typography color="inherit">{phoneNumber.title}</Typography>
              </PhoneLink>
            )}
          </Address>
          <Socials>
            <SocialIcons socials={socials} />
          </Socials>
          <LogoContainer to="/">
            <Logo />
          </LogoContainer>
        </Flex>
      </Edges>
    </Footer>
  )
}

const Footer = styled.footer`
  @media (min-width: 800px) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  padding-top: 30px;
  padding-bottom: 30px;
  color: white;
  background-color: ${({ theme }) => theme.colors.primary};
`
const Flex = styled.div`
  @media (min-width: 800px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Address = styled.div`
  @media (max-width: 799px) {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    a {
      position: relative;
      left: -13px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  color: #fff;
`

const PhoneLink = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  svg {
    margin-right: 3px;
    path {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const Socials = styled.div`
  @media (min-width: 800px) {
    padding-top: 30px;
    a {
      margin-right: 20px;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  a {
    display: block;
    padding: 10px;
    &:hover {
      svg {
        path {
          fill: ${({ theme }) => theme.colors.secondary};
        }
      }
    }
  }
`

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 799px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  a {
    color: inherit;
    text-decoration: none;
    padding: 10px;
    margin-right: 10px;
    > div {
      text-transform: uppercase;
      font-family: "Amiri";
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.5px;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }
`

const LogoContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`
