import produce from "immer"
import { unionBy } from "lodash"

export const appState = {
  loginDialog: false,
  bookmarksDialog: false,
  progress: false,
  menu: false,
  feedback: false,
  snackbar: null,
  lightBox: {
    slide: 0,
    open: false,
    slides: [],
    options: {
      thumbs: true
    }
  },
  cart: {
    open: false,
    items: []
  },
  product: {
    open: false,
    item: null
  }
}

export const appReducer = (state, action) => {
  const { payload } = action

  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_MENU":
        draft.menu = payload
        break

      case "TOGGLE_MENU":
        draft.menu = !draft.menu
        break

      case "SET_PROGRESS":
        draft.progress = payload
        break

      case "SET_SNACKBAR":
        draft.snackbar = payload
        break

      case "SET_BOOKMARKS_DIALOG":
        draft.bookmarksDialog = payload
        break

      case "SET_LOGIN_DIALOG":
        draft.loginDialog = payload
        break

      case "SET_FEEDBACK_DIALOG":
        draft.feedback = payload
        break

      case "SET_LIGHTBOX":
        draft.lightBox = { ...draft.lightBox, ...payload }
        break

      case "CLOSE_LIGHTBOX":
        draft.lightBox = {
          slide: 0,
          open: false,
          slides: [],
          options: {
            thumbs: true
          }
        }
        break

      case "TOGGLE_CART":
        draft.cart.open = payload
        break

      case "ADD_TO_CART":
        draft.cart.items = unionBy(payload, draft.cart.items, "id")
        break

      case "REMOVE_FROM_CART":
        draft.cart.items = draft.cart.items.filter((o) => o.id !== payload)
        break

      case "EMPTY_CART":
        draft.cart.items = []
        break

      case "SET_CART_ITEMS":
        draft.cart.items = payload
        break

      case "TOGGLE_PRODUCT":
        draft.product = payload
        break

      default:
    }
  })
}
