import React, { useRef, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import {
  isIE,
  isEdge,
  isSafari,
  isMobile,
  isChromium
} from "react-device-detect"

import BackgroundImage from "../backgroundImage"
import { useStore } from "../../store"
import useWindowSize from "../../utils/useWindowSize"

import { ChevronRight, ChevronLeft } from "mdi-material-ui"

export default function Thumbs(props) {
  const [
    {
      appState: {
        lightBox: { slide, slides }
      }
    },
    dispatch
  ] = useStore()

  const windowSize = useWindowSize()

  const [currentThumbRect, setCurrentThumbRect] = useState(false)

  const [shouldShowControls, setShouldShowControls] = useState(true)

  const goToSlide = (index) => {
    dispatch({ type: "SET_LIGHTBOX", payload: { slide: index } })
  }

  const containerRef = useRef(null)

  const thumbsArray =
    !!containerRef && !!containerRef.current && containerRef.current.children

  const oldBrowser = isIE || isEdge || isSafari || (isMobile && !isChromium)

  const scrollOptions = !oldBrowser && {
    behavior: "smooth"
  }

  useEffect(() => {
    !!thumbsArray &&
      !!thumbsArray[slide] &&
      setCurrentThumbRect(thumbsArray[slide].getBoundingClientRect())
  }, [slide])

  useEffect(() => {
    scrollToCurrentThumb()
  }, [currentThumbRect])

  const scrollToCurrentThumb = () => {
    if (thumbsArray && currentThumbRect && windowSize) {
      // if right is out of view
      currentThumbRect.right > windowSize.width &&
        thumbsArray[slide].scrollIntoView(scrollOptions)
      // if left is out of view
      currentThumbRect.left < 0 &&
        thumbsArray[slide].scrollIntoView(scrollOptions)
    }
  }

  const lastChild = containerRef?.current?.lastChild

  useEffect(() => {
    checkControls()
  }, [lastChild])

  useEffect(() => {
    checkControls()
  }, [windowSize])

  const checkControls = () => {
    if (!!lastChild) {
      // if last child is off screen then show arrow controls
      lastChild.getBoundingClientRect().right > windowSize.width
        ? setShouldShowControls(true)
        : setShouldShowControls(false)
    }
  }

  const scrollToRight = () => {
    !!containerRef?.current &&
      containerRef.current.scrollBy({
        left: windowSize.width,
        behavior: "smooth"
      })
  }

  const scrollToLeft = () => {
    !!containerRef?.current &&
      containerRef.current.scrollBy({
        left: -windowSize.width,
        behavior: "smooth"
      })
  }

  return (
    <Container ref={containerRef}>
      {!!shouldShowControls && (
        // <Controls className="controls">
        <>
          <ArrowRight className="arrow-button" onClick={() => scrollToRight()}>
            <ChevronRight />
          </ArrowRight>

          <ArrowLeft className="arrow-button" onClick={() => scrollToLeft()}>
            <ChevronLeft />
          </ArrowLeft>
        </>
        // </Controls>
      )}

      {!!slides &&
        slides.map((item, index) => {
          return (
            <Thumb
              key={index}
              onClick={() => goToSlide(index)}
              selected={index === slide}
            >
              {!!item && item.image && <BackgroundImage bg={item.image} />}
            </Thumb>
          )
        })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  &:hover {
    .arrow-button {
      opacity: 1;
    }
  }
`
const Thumb = styled.div`
  &:after {
    transition: all 0.2s ease-in-out;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    ${(props) =>
      props.selected &&
      css`
        background: rgba(0, 0, 0, 0);
        border: 2px solid white;
      `}
  }
  &:hover {
    &:after {
      background: rgba(0, 0, 0, 0);
    }
  }
  position: relative;
  height: 100px;
  width: 200px;
  background: lightgrey;
  cursor: pointer;
  flex-shrink: 0;
`

const arrowButton = css`
  @media (min-width: 800px) {
    opacity: 0;
  }
  svg {
    fill: white;
    width: 30px;
    height: 30px;
  }
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
  padding: 10px;
  background: rgba(0, 0, 0, 0.8);
  transition: border 0.2s ease-in-out;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid white;
  }
`
const ArrowRight = styled.div`
  ${arrowButton}
  position: fixed;
  bottom: 25px;
  right: 0;
  z-index: 88;
`

const ArrowLeft = styled.div`
  ${arrowButton}
  position: fixed;
  bottom: 25px;
  left: 0;
  z-index: 88;
`
