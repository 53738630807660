import React, { useState } from "react"
import styled, { css } from "styled-components"
// import excerptHtml from "excerpt-html"
import Parser from "html-react-parser"

// import material ui
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import CloseIcon from "react-ionicons/lib/IosClose"
import RefreshIcon from "react-ionicons/lib/IosRefresh"

// import app components
// import BackgroundImage from "../backgroundImage"
import { useStore } from "../../store"

import * as theme from "../../theme"
import { GatsbyImage } from "gatsby-plugin-image"

export default function Product(props) {
  const { target, id, product, title, uri } = props

  const [amount, setAmount] = useState(props?.amount ? props?.amount : 1)

  const [
    {
      appState: {
        cart: { items }
      }
    },
    dispatch
  ] = useStore()

  console.log("first,items", items)

  const onAddToCart = () => {
    const productIndex = items.findIndex((item) => item.id === id)

    const newAmount =
      target === "archive" && productIndex !== -1
        ? parseInt(items[productIndex].amount) + parseInt(amount)
        : parseInt(amount)

    dispatch({
      type: "ADD_TO_CART",
      payload: [
        {
          ...props,
          amount: newAmount
        }
      ]
    })

    if (target === "archive" || target === "single") {
      const message = `${amount}x ${title} added to cart.`
      dispatch({ type: "SET_SNACKBAR", payload: message })

      setAmount(1)
    }
  }

  const onRemove = () => {
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: id
    })
  }

  const onOpen = () => {
    if (target !== "single" && target !== "checkout") {
      dispatch({
        type: "TOGGLE_PRODUCT",
        payload: {
          open: true,
          item: props
        }
      })
    }
  }

  return (
    <Item {...props} className="Item">
      {target === "cart" && (
        <Tooltip title="Remove" aria-label="Remove">
          <ItemRemove>
            <ItemRemoveIcon onClick={onRemove} />
          </ItemRemove>
        </Tooltip>
      )}

      <ItemImage
        {...{ target }}
        className="ItemImage"
        onClick={onOpen}
        pointer={target === "single" ? "false" : "true"}
      >
        {product.image && (
          <GatsbyImage
            image={product.image.localFile?.childImageSharp?.gatsbyImageData}
            alt={product.image?.altText}
          />
        )}
      </ItemImage>

      <ItemContent className="ItemContent">
        <ItemText className="ItemText">
          <ItemTitle
            {...{ target }}
            className="ItemTitle"
            variant="h4"
            component="h3"
            children={title}
            onClick={onOpen}
            gutterBottom={target === "archive"}
          />

          {target === "single" && product.description && (
            <ItemDescription
              className="ItemDescription"
              children={Parser(product.description)}
            />
          )}
        </ItemText>

        <ItemMeta className="ItemMeta" {...{ target }}>
          <ItemPrice
            className="ItemPrice"
            variant={target === "archive" ? "h3" : "body1"}
            component="span"
            children={`$${parseFloat(product.price).toFixed(2)}`}
          />
          {target === "archive" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Typography style={{ marginRight: "10px" }}>QTY</Typography>
              <ItemAmount
                value={amount}
                type="number"
                onChange={(event) => setAmount(+event.target.value)}
                InputProps={{ inputProps: { min: 1 } }}
                className="ItemAmount"
                onKeyPress={(event) => event.key === "Enter" && onAddToCart()}
              />
            </div>
          )}

          <ItemAdd className="ItemAdd">
            {target === "checkout" && <ItemAmountText children={`${amount}`} />}

            {(target === "cart" || target === "single") && (
              <ItemAmount
                value={amount}
                type="number"
                onChange={(event) => setAmount(+event.target.value)}
                InputProps={{ inputProps: { min: 1 } }}
                className="ItemAmount"
                onKeyPress={(event) => event.key === "Enter" && onAddToCart()}
              />
            )}

            {(target === "archive" || target === "single") && (
              <Button children={"Add to Cart"} onClick={onAddToCart} />
            )}

            {target === "cart" && (
              <Tooltip title="Update" aria-label="Update">
                <ItemUpdate>
                  <RefreshIcon
                    onClick={onAddToCart}
                    style={{ cursor: "pointer" }}
                  />
                </ItemUpdate>
              </Tooltip>
            )}

            {(target === "cart" || target === "checkout") && (
              <ItemTotalPrice
                children={`$${Math.round(amount * product.price * 100) / 100}`}
              />
            )}
          </ItemAdd>
        </ItemMeta>
      </ItemContent>
    </Item>
  )
}

const Item = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
`

const ItemRemove = styled.div`
  width: 30px;
`

const ItemRemoveIcon = styled(CloseIcon)`
  cursor: pointer;
`

const ItemImage = styled.div`
  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    max-width: 400px;
  }
  width: 100%;
  ${({ target }) =>
    target === "archive" &&
    css`
      cursor: pointer;
    `}
`

const ItemContent = styled.div`
  flex: 1;
`

const ItemTitle = styled(Typography)`
  && {
    ${({ target }) =>
      target === "archive" &&
      css`
        cursor: pointer;
        text-align: center;
      `}
  }
`

const ItemDescription = styled.div``

const ItemText = styled.div``

const ItemMeta = styled.div`
  ${({ target }) =>
    target === "archive" &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    `}
`

const ItemPrice = styled(Typography)``

const ItemAdd = styled.div``

const ItemUpdate = styled.div`
  width: 30px;
`

const ItemAmount = styled(TextField)`
  @media (min-width: 400px) {
    width: 50px;
  }
`

const ItemAmountText = styled(Typography)`
  @media (min-width: 400px) {
    width: 50px;
  }
`

const ItemTotalPrice = styled(Typography)`
  @media (min-width: 400px) {
    width: 80px;
    text-align: right;
  }
`
