import React from "react"

import FacebookIcon from "../icons/fb.svg"
import LinkedInIcon from "../icons/linkedin.svg"
import TwitterIcon from "../icons/twitter.svg"
import YouTubeIcon from "../icons/youtube.svg"
import InstagramIcon from "../icons/insta.svg"

export default ({ socials }) => {
  return (
    <>
      {!!socials &&
        socials.length &&
        socials.map(({ icon, link }, index) => {
          let Svg = FacebookIcon

          switch (icon) {
            case "facebook":
              Svg = FacebookIcon
              break

            case "linkedin":
              Svg = LinkedInIcon
              break

            case "twitter":
              Svg = TwitterIcon
              break

            case "youtube":
              Svg = YouTubeIcon
              break

            case "instagram":
              Svg = InstagramIcon
              break

            default:
          }

          return (
            <a href={link.url} key={index} target="_blank" rel="noopener">
              <Svg />
            </a>
          )
        })}
    </>
  )
}
