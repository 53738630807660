import React, { createContext, useContext, useReducer } from "react"
import { authState, authReducer } from "./authState"
import { appState, appReducer } from "./appState"
import { userState, userReducer } from "./userState"

export const StateContext = createContext({})

export const Store = props => {
  const initialState = {
    authState,
    appState,
    userState
  }

  const reducer = ({ authState, appState, userState }, action) => ({
    authState: authReducer(authState, action),
    appState: appReducer(appState, action),
    userState: userReducer(userState, action)
  })

  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </StateContext.Provider>
  )
}

export const useStore = () => useContext(StateContext)
