import React from "react"
import styled from "styled-components"

// import material ui
import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "react-ionicons/lib/IosClose"

// import app components
import Product from "../../components/product"
import { useStore } from "../../store"

import * as theme from "../../theme"

export default function ProductSingle() {
  const [
    {
      appState: {
        product: { open, item }
      }
    },
    dispatch
  ] = useStore()

  return (
    <StyledDialog
      open={open}
      onClose={() =>
        dispatch({
          type: "TOGGLE_PRODUCT",
          payload: {
            open: false,
            item: null
          }
        })
      }
      fullWidth
      maxWidth="md"
    >
      <Container>
        <CloseButton
          onClick={() =>
            dispatch({ type: "TOGGLE_PRODUCT", payload: { open: false } })
          }
        >
          <CloseIcon />
        </CloseButton>
        {item && (
          <Item>
            <Product {...item} target="single" />
          </Item>
        )}
      </Container>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)`
  @media screen and (max-width: 400px) {
    .MuiDialog-paper {
      margin: 0;
    }
    .MuiDialog-paperFullWidth {
      width: calc(100% - 10px);
    }
    .MuiDialog-paperScrollPaper {
      max-height: calc(100% - 10px);
    }
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
`
const Container = styled.div`
  min-height: 60vh;
`

const Item = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;

  .Item {
    @media screen and (max-width: ${theme.mui.breakpoints.values.sm}px) {
      flex-direction: column;
    }
  }

  .ItemImage {
    padding: 10px;
  }

  .ItemText {
    flex: 1;
  }

  .ItemMeta {
    display: flex;
    flex-direction: column;
    @media (min-width: 400px) {
      flex-direction: row;
    }
  }

  .ItemTitle {
    font-size: 18px;
    line-height: 2;
  }

  .ItemContent {
    @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
      width: 50%;
    }
    padding: 20px;
  }

  .ItemPrice {
    width: 100px;
    display: flex;
    align-items: center;
  }

  .ItemAdd {
    display: flex;
    align-items: center;
    @media (max-width: 399px) {
      justify-content: space-between;
      .MuiInput-input {
        max-width: 20px;
      }
    }
  }

  .ItemAmount {
    margin-right: 30px;
  }
`
