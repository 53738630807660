import React from "react"
import styled, { css } from "styled-components"

import { OpenInNew, Close } from "mdi-material-ui"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"

import YouTube from "./YouTube"
import BackgroundImage from "../backgroundImage"
import { useStore } from "../../store"

export default function Slide(props) {
  const dispatch = useStore()[1]

  const {
    slide: { image, link }
  } = props

  const { resize } = !!image && !!image.childImageSharp && image.childImageSharp
  const youtube = !!link && link.includes("youtu")
  return (
    <Container>
      {!!link && (
        <LinkToPost href={link} target="_blank" rel="noopener referrer">
          <Tooltip
            title={!youtube ? "View on Instagram" : "Watch on YouTube"}
            placement="right"
          >
            <IconButton aria-label="View on Instagram">
              <OpenInNew />
            </IconButton>
          </Tooltip>
        </LinkToPost>
      )}
      <ExitButton
        aria-label="Close"
        onClick={() =>
          dispatch({
            type: "CLOSE_LIGHTBOX"
          })
        }
      >
        <IconButton aria-label="Close">
          <Close />
        </IconButton>
      </ExitButton>

      {!!youtube && <YouTube video={link} />}
      {!!image && (
        <ImageContainer resize={resize}>
          <BackgroundImage bg={image} position="absolute" />
        </ImageContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  ${(props) =>
    props.resize &&
    css`
      width: ${props.resize.width * 1.5}px;
      height: ${props.resize.height * 1.5}px;
    `}
  max-height: 60vh;
  max-width: 98vw;
  position: relative;
`

const ExitButton = styled.div`
  position: absolute;
  top: -48px;
  right: 0;
  svg {
    fill: white;
  }
`
const LinkToPost = styled.a`
  z-index: 1;
  text-decoration: none;
  color: inherit;
  position: absolute;
  top: -50px;
  left: 0;
  cursor: pointer;
  svg {
    fill: white;
  }
`
