import React from "react"
import styled, { css } from "styled-components"

import useWindowSize from "../../utils/useWindowSize"

export default function MediaQuery(props) {
  const { maxWidth, minWidth } = props
  const size = useWindowSize()
  const shouldHide = !!maxWidth ? size.width > maxWidth : !!minWidth && size.width < minWidth
  return <StyledQuery {...props} hidden={shouldHide} aria-hidden={shouldHide} />
}

const StyledQuery = styled.div`
  ${props =>
    props.minWidth &&
    css`
      @media (max-width: ${props.minWidth + "px"}) {
        display: none !important;
      }
    `}

  ${props =>
    props.maxWidth &&
    css`
      @media (min-width: ${props.maxWidth + "px"}) {
        display: none !important;
      }
    `}
`
