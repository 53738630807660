import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"
import useMenuItems from "./useMenuItems"

// import material ui
import Typography from "@material-ui/core/Typography"

import ArrowDown from "react-ionicons/lib/IosArrowDown"

// import app components
import * as theme from "../../theme"

const DesktopMenu = (props) => {
  const items = useMenuItems("desktop-main-menu")

  return (
    <Menu {...props}>
      {items &&
        items.map(({ id, url, label, childItems }) => (
          <MenuItem key={id}>
            {childItems && childItems.nodes.length ? (
              <>
                <MenuLink to={url} activeClassName="active-item">
                  <Typography color="inherit" component="div">
                    {Parser(label)}
                    <ArrowDown />
                  </Typography>
                </MenuLink>

                <SubMenu className="sub-menu">
                  {childItems.nodes.map((o) => (
                    <SubMenuItem
                      key={o.url}
                      to={o.url}
                      activeClassName="active-item"
                    >
                      <Typography
                        color="inherit"
                        component="div"
                        children={Parser(o.label)}
                      />
                    </SubMenuItem>
                  ))}
                </SubMenu>
              </>
            ) : (
              <MenuLink to={url} activeClassName="active-item">
                <Typography
                  color="inherit"
                  component="div"
                  children={Parser(label)}
                />
              </MenuLink>
            )}
          </MenuItem>
        ))}
    </Menu>
  )
}

export default DesktopMenu

const Menu = styled.div`
  display: flex;
  @media (max-width: 980px) {
    justify-content: space-between;
  }
  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  @media (min-width: 1100px) and (max-width: 1199px) {
    margin-right: 15px;
  }
  @media (min-width: 1200px) {
    margin-right: 35px;
  }
  display: flex;
  position: relative;
  &:last-of-type {
    margin-right: 0;
  }
  &:hover {
    .sub-menu {
      opacity: 1;
      pointer-events: all;
      transform: perspective(200px) translateZ(0px) translateY(0px)
        translateX(-50%) rotateX(0deg);
    }
  }
  .sub-menu {
    opacity: 0;
    pointer-events: none;
    transition: all 200ms ease-in-out;
    transform-origin: top center;
    transform: perspective(200px) translateZ(0px) translateY(0px)
      translateX(-50%) rotateX(-90deg);
  }
  .active-item {
    color: ${({ theme }) => theme.colors.secondary};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`

const MenuLink = styled(Link)`
  color: ${theme.colors.primary};
  padding: 5px 5px 15px;
  transition: ease color 0.2s;

  &:hover {
    color: ${theme.colors.secondary};
    svg {
      path {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 1px;
    svg {
      margin-left: 5px;
    }
  }
`

const SubMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% + 10px);
  left: 50%;
  transition: ease all 0.4s;
  background: ${theme.colors.secondary};
  padding: 10px 0;
  z-index: 999;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: 20px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${theme.colors.secondary};
  }

  .active-item {
    color: #fff;
  }
`

const SubMenuItem = styled(Link)`
  padding: 20px 30px;
  flex-shrink: 0;
  white-space: nowrap;
  color: #e4f2f5;
  transition: ease-in 0.2s all;

  &:hover {
    background: #fff;
    color: ${theme.colors.secondary};
  }
  > div {
    font-size: 15px;
    letter-spacing: 1px;
  }
`
