import React from "react"
import styled, { ThemeProvider } from "styled-components"
import Helmet from "react-helmet"
import { ApolloProvider } from "react-apollo-hooks"
import { MuiThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import SEO from "../../components/seo"

import "core-js/es6/number"
import "core-js/es6/array"

// import app components
import Header from "../../components/header"
import Footer from "../../components/footer"
import client from "../../components/authentication/client"
import LightBox from "../../components/lightBox"
// import others
import { mui } from "../../theme"
import { Store } from "../../store"

import * as theme from "../../theme"

const Layout = (props) => {
  const { pageContext } = props

  return (
    <Store>
      <ApolloProvider client={client}>
        <>
          <Helmet>
            <html lang="en" />
            <meta name="description" />
          </Helmet>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={mui}>
              <CssBaseline />
              <SEO {...pageContext} />

              <Seperator>
                <Header path={props.path} />
                <Page>{props.children}</Page>
                <Footer />
              </Seperator>
              <LightBox />
            </MuiThemeProvider>
          </ThemeProvider>
        </>
      </ApolloProvider>
    </Store>
  )
}

const Seperator = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  background: white;
`
const Page = styled.div`
  background: white;
  overflow: hidden;
  @media (min-width: 961px) {
    padding-top: 100px;
  }
  @media (min-width: 600px) and (max-width: 960px) {
    padding-top: 64px;
  }
  padding-top: 56px;
`

export default Layout
