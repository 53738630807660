import produce from "immer"
import { getAuth } from "../components/authentication/getAuth"

const auth = getAuth()

export const authState = {
  token: null,
  token_expiry: null,
  refresh_token: null,
  is_token_expired: true,
  isAuthed: false,
  uniqueId: null,
  ...auth
}

export const authReducer = (state, action) => {
  const { payload } = action

  return produce(state, draft => {
    switch (action.type) {
      case "LOGIN":
        draft.token = payload.token
        draft.uniqueId = payload.uniqueId
        draft.token_expiry = payload.token_expiry
        draft.refresh_token = payload.refresh_token
        draft.is_token_expired = payload.is_token_expired
        draft.isAuthed = true
        break

      case "LOGOUT":
        localStorage.removeItem("starter.data")

        draft.token = null
        draft.token_expiry = null
        draft.refresh_token = null
        draft.is_token_expired = true
        draft.isAuthed = false
        draft.uniqueId = null
        break

      default:
    }
  })
}
