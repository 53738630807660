import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import CloseIcon from "react-ionicons/lib/IosClose"
// import material ui
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Input from "@material-ui/core/Input"

// import app components
import { useStore } from "../../store"
import useTotal from "./useTotal"
import { GatsbyImage } from "gatsby-plugin-image"
import { clearConfigCache } from "prettier"

export default function Cart() {
  const [
    {
      appState: {
        cart: { open }
      }
    },
    dispatch
  ] = useStore()

  return (
    <Dialog
      open={open}
      onClose={() =>
        dispatch({
          type: "TOGGLE_CART",
          payload: false
        })
      }
      fullWidth
      maxWidth="md"
    >
      <Container>
        <CloseButton
          onClick={() => dispatch({ type: "TOGGLE_CART", payload: false })}
        >
          <CloseIcon />
        </CloseButton>
        <CartContent />
      </Container>
    </Dialog>
  )
}
const Container = styled.div`
  min-height: 60vh;
  padding: 20px;
`
const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 10px;
`

export const CartContent = () => {
  const [
    {
      appState: {
        cart: { items }
      }
    },
    dispatch
  ] = useStore()

  const total = useTotal(items)

  const onCheckout = () => {
    dispatch({
      type: "TOGGLE_CART",
      payload: false
    })
    navigate("/checkout")
  }

  const goToShop = () => {
    dispatch({
      type: "TOGGLE_CART",
      payload: false
    })
    navigate("/shop")
  }
  return (
    <>
      {!!items && items.length ? (
        <>
          <Items>
            {items.map((item) => {
              const {
                id,
                title,
                amount,
                product: { image, price }
              } = item

              const handleChange = (item) => (event) => {
                if (event.target.value > 0) {
                  const existingItemIndex = items.findIndex(
                    (cartItem) => cartItem.title === item.title
                  )
                  const itemExists = existingItemIndex > -1

                  if (itemExists) {
                    const updatedItems = [...items]
                    const existingItem = updatedItems[existingItemIndex]
                    existingItem.amount = event.target.value
                    dispatch({
                      type: "SET_CART_ITEMS",
                      payload: updatedItems
                    })
                  }
                }
              }

              const removeFromCart = (item) => {
                dispatch({ type: "REMOVE_FROM_CART", payload: item.id })
              }

              return (
                <CartItem key={id}>
                  {!!image && (
                    <ImageContainer>
                      <GatsbyImage
                        image={
                          image?.localFile?.childImageSharp?.gatsbyImageData
                        }
                      />
                    </ImageContainer>
                  )}
                  <ItemInfo>
                    <Typography>{title}</Typography>
                    <Controls>
                      <Typography
                        variant="caption"
                        style={{ marginRight: "20px" }}
                      >
                        QTY
                      </Typography>
                      <InputAmount
                        type="number"
                        inputProps={{ min: 1, step: 1 }}
                        value={amount}
                        onChange={handleChange(item)}
                      />
                      <Button onClick={() => removeFromCart(item)}>
                        Remove
                      </Button>
                    </Controls>
                    <Typography>
                      ${price}{" "}
                      {amount > 1 && (
                        <>(${parseFloat((price * amount).toFixed(2))})</>
                      )}
                    </Typography>
                  </ItemInfo>
                </CartItem>
              )
            })}
          </Items>
          <Typography gutterBottom>Total: ${total}</Typography>
          <BottomButtons>
            <Button onClick={onCheckout} fullWidth className="checkout-button">
              Proceed to checkout
            </Button>
            <Button onClick={goToShop} fullWidth className="shop-button">
              Return to shop
            </Button>
          </BottomButtons>
        </>
      ) : (
        <Empty>
          <Typography gutterBottom>Your cart is empty.</Typography>
          <Button fullWidth onClick={goToShop}>
            Go to shop
          </Button>
        </Empty>
      )}
    </>
  )
}

const BottomButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .checkout-button {
    margin-bottom: 20px;
  }
  @media screen and (min-width: 400px) {
    margin-bottom: 20px;
    flex-direction: row;
    .checkout-button {
      margin-bottom: 0;
      margin-right: 10px;
    }
    .shop-button {
      margin-left: 10px;
    }
  }
`

const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
`
const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const CartItem = styled.div`
  padding: 20px 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.springWood};
`
const ItemInfo = styled.div`
  width: 100%;
`
const Controls = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (min-width: 400px) {
    justify-content: flex-end;
  }
`
const ImageContainer = styled.div`
  width: 120px;
  margin-right: 20px;
`
const InputAmount = styled(Input)`
  max-width: 30px;
  margin-right: 10px;
`
