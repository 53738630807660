import React from "react"
import Parser from "html-react-parser"
import Typography from "@material-ui/core/Typography"

import Link from "../link"
import useMenuItems from "./useMenuItems"

export default () => {
  const items = useMenuItems("footer-menu")

  return (
    <>
      {items &&
        items.map(({ id, url, label }) => (
          <Link key={id} to={url}>
            <Typography color="inherit" component="div">
              {Parser(label)}
            </Typography>
          </Link>
        ))}
    </>
  )
}
