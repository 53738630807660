import React, { useState } from "react"
import styled from "styled-components"
import { useMutation } from "react-apollo-hooks"
import gql from "graphql-tag"

// import material ui
import DialogTitle from "@material-ui/core/DialogTitle"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

// import app components
import Spacer from "../spacer"
import { setAuth } from "./setAuth"
import { useStore } from "../../store"

const LoginUser = props => {
  const [
    {
      appState: { loginDialog }
    },
    dispatch
  ] = useStore()

  const [loggingIn, setLoggingIn] = useState(true)
  const [formError, updateFormErrors] = useState(false)
  const [isLoading, updateIsLoading] = useState(false)

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const resetPassword = useMutation(RESET_PASSWORD)
  const loginUser = useMutation(LOGIN_USER)

  const onSubmit = async e => {
    if (!loggingIn) {
      submitPasswordReset({
        e,
        username,
        updateFormErrors,
        updateIsLoading,
        resetPassword
      })
      return
    }

    if (!username || !password) {
      updateFormErrors("Please enter a username and password")
      return
    }

    dispatch({ type: "SET_PROGRESS", payload: true })

    // logout first to delete local storage data
    dispatch({ type: "LOGOUT" })

    let response
    let message
    try {
      response = await loginUser({
        variables: {
          username,
          password
        }
      })
    } catch (e) {
      message = e.message
      if (
        message.includes("incorrect_password") ||
        message.includes("invalid_username")
      ) {
        updateFormErrors("Incorrect email or password.")
      } else {
        updateFormErrors(message)
      }
    }

    if (response && response.data.login.authToken) {
      const authData = setAuth(response.data.login)
      dispatch({ type: "LOGIN", payload: authData })

      setUsername("")
      setPassword("")

      dispatch({ type: "SET_LOGIN_DIALOG", payload: false })
    }

    dispatch({ type: "SET_PROGRESS", payload: false })
  }

  const submitPasswordReset = async ({
    username,
    updateFormErrors,
    resetPassword,
    updateIsLoading
  }) => {
    // e.preventDefault()

    dispatch({ type: "SET_PROGRESS", payload: true })

    updateFormErrors(false)

    let message = false
    let passresetResponse
    try {
      passresetResponse = await resetPassword({
        variables: {
          username: username
        }
      })
    } catch (e) {
      message = e.message
    }

    if (!message && passresetResponse?.data?.sendPasswordResetEmail?.user) {
      message = `Password reset email sent`
    } else if (!message) {
      message = `Password reset failed`
    }
    updateFormErrors(message)

    dispatch({ type: "SET_PROGRESS", payload: false })
  }

  const catchReturn = event => {
    if (event.charCode === 13) {
      // enter key pressed
      onSubmit()
    }
  }

  return (
    <Dialog
      open={loginDialog}
      onClose={() => dispatch({ type: "SET_LOGIN_DIALOG", payload: false })}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle children={!!loggingIn ? "Login" : "Reset Password"} />
      <DialogContent>
        {!!formError && <FormError>{formError}</FormError>}
        <Spacer pb={20}>
          <StyledInput
            fullWidth
            type="text"
            placeholder="Email"
            onChange={({ target }) => setUsername(target.value)}
            onKeyPress={(event) => catchReturn(event)}
          />
        </Spacer>
        {!!loggingIn && (
          <Spacer pb={20}>
            <StyledInput
              fullWidth
              type="password"
              placeholder="Password"
              onChange={({ target }) => setPassword(target.value)}
              onKeyPress={(event) => catchReturn(event)}
            />
          </Spacer>
        )}

        <Grid container justifyContent="space-between">
          <Button
            children={!!loggingIn ? "Reset Password" : "Login"}
            onClick={() => setLoggingIn(!loggingIn)}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            children={!!loggingIn ? "Login" : "Reset"}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

const FormError = styled(Typography)``

const StyledInput = styled(TextField)`
  && {
    background: white;
    padding: 0 10px;
  }
`

export default LoginUser

const LOGIN_USER = gql`
  mutation LoginUser($username: String!, $password: String!) {
    login(
      input: {
        clientMutationId: "uniqueId"
        username: $username
        password: $password
      }
    ) {
      authToken
      refreshToken
      user {
        id
        name
        jwtAuthExpiration
        roles
      }
    }
  }
`

const RESET_PASSWORD = gql`
  mutation SendPasswordResetEmail($username: String!) {
    sendPasswordResetEmail(
      input: { username: $username, clientMutationId: "uniqueId" }
    ) {
      user {
        username
      }
    }
  }
`
