exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page/contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-default-js": () => import("./../../../src/templates/page/default.js" /* webpackChunkName: "component---src-templates-page-default-js" */),
  "component---src-templates-post-default-js": () => import("./../../../src/templates/post/default.js" /* webpackChunkName: "component---src-templates-post-default-js" */)
}

