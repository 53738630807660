import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import HamburgerMenu from "react-hamburger-menu"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"

// import material ui
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Badge from "@material-ui/core/Badge"
import Grid from "@material-ui/core/Grid"
import LinearProgress from "@material-ui/core/LinearProgress"
import Snackbar from "@material-ui/core/Snackbar"
import CloseIcon from "react-ionicons/lib/IosClose"
import CartIcon from "react-ionicons/lib/IosCart"

// import app components
import MediaQuery from "../mediaQuery"
import Login from "../authentication/Login"
import DesktopMenu from "../menu/DesktopMenu"
import MobileMenu from "../menu/MobileMenu"
import UtilityMenu from "../menu/UtilityMenu"
import Cart from "../cart"
import ProductSingle from "../productSingle"
import Edges from "../edges"
import Logo from "../../images/vwmc-logo.svg"
import { useStore } from "../../store"
import useWindowSize from "../../utils/useWindowSize"
import useItemCount from "../cart/useItemCount"

import * as theme from "../../theme"

const Header = props => {
  const { path } = props

  const windowSize = useWindowSize()
  const isMobile = windowSize.width <= theme.mui.breakpoints.values.md

  const [
    {
      appState: {
        progress,
        menu,
        snackbar,
        loginDialog,
        cart: { items }
      }
    },
    dispatch
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path])

  useEffect(() => {
    if (
      typeof localStorage !== "undefined" &&
      localStorage.getItem("vmwc-cart")
    ) {
      const cart = JSON.parse(localStorage.getItem("vmwc-cart"))
      dispatch({
        type: "ADD_TO_CART",
        payload: cart
      })
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("vmwc-cart", JSON.stringify(items))
  }, [items])

  const snack = (
    <StyledSnackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={!!snackbar}
      autoHideDuration={2000}
      onClose={() => dispatch({ type: "SET_SNACKBAR", payload: null })}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">{snackbar}</span>}
      action={[
        <CloseIcon
          key="close"
          onClick={() => dispatch({ type: "SET_SNACKBAR", payload: null })}
        />
      ]}
    />
  )

  const cartAmount = useItemCount(items)

  return (
    <>
      <StyledAppBar position="fixed">
        <Toolbar>
          <Edges size="lg">
            <Grid container justifyContent="space-between" alignItems="center">
              <Link to="/">
                <LogoContainer
                  onClick={() => dispatch({ type: "SET_MENU", payload: false })}
                >
                  <Logo className="logo-image" />
                </LogoContainer>
              </Link>

              <Navigation minWidth={theme.mui.breakpoints.values["md"]}>
                <TopBar>
                  {snack}

                  <Grid container justifyContent="flex-end" alignItems="center">
                    <UtilityMenuContainer>
                      <MediaQuery minWidth={theme.mui.breakpoints.values["md"]}>
                        <UtilityMenu />
                      </MediaQuery>
                      <CartContainer
                        onClick={() =>
                          !isMobile
                            ? dispatch({
                                type: "TOGGLE_CART",
                                payload: true
                              })
                            : navigate("/cart")
                        }
                      >
                        <Badge badgeContent={cartAmount} color="secondary">
                          <CartIcon fontSize="20px" />
                        </Badge>
                      </CartContainer>
                    </UtilityMenuContainer>
                  </Grid>
                </TopBar>

                <MediaQuery minWidth={theme.mui.breakpoints.values["md"]}>
                  <DesktopMenu />
                </MediaQuery>
              </Navigation>

              <MediaQuery maxWidth={theme.mui.breakpoints.values["md"] + 1}>
                <HamburgerMenuContainer
                  onClick={() => dispatch({ type: "TOGGLE_MENU" })}
                >
                  <HamburgerMenu
                    color={theme.colors.primary}
                    isOpen={menu}
                    width={18}
                    height={15}
                    strokeWidth={1}
                    menuClicked={() => ""}
                  />
                </HamburgerMenuContainer>
              </MediaQuery>
            </Grid>
          </Edges>
        </Toolbar>
      </StyledAppBar>

      <MediaQuery maxWidth={theme.mui.breakpoints.values["md"] + 1}>
        <MobileMenu />
      </MediaQuery>

      {progress && <StyledLinearProgress color="primary" />}
      {loginDialog && <Login />}

      <ProductSingle />
      <Cart />
    </>
  )
}

const StyledSnackbar = styled(Snackbar)`
  position: absolute;
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.sm}px) {
    top: 110px;
  }
  top: 70px;
  right: 15px;
`

const StyledAppBar = styled(AppBar)`
  && {
    box-shadow: none;
    background: ${({ theme }) => theme.colors.springWood};

    > div {
      padding: 0;
    }
  }
`

const LogoContainer = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.mui.breakpoints.values.sm - 1}px) {
      .logo-image {
        width: 200px;
      }
    }
    @media (min-width: ${theme.mui.breakpoints.values
        .sm}px) and (max-width: ${theme.mui.breakpoints.values.md - 1}px) {
      .logo-image {
        width: 240px;
      }
    }
    @media (min-width: ${theme.mui.breakpoints.values.md}px) {
      .logo-image {
        width: 280px;
      }
    }
  `}
`

const HamburgerMenuContainer = styled.div`
  cursor: pointer;
  padding: 15px;
`

const Navigation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`

const TopBar = styled.div`
  /* position: relative; */
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (min-width: ${theme.mui.breakpoints.values.md}px) {
    padding-top: 10px;
    padding-right: 12px;
  }
`

const UtilityMenuContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md}px) {
    margin-bottom: 14px;
  }
`

const CartContainer = styled.div`
  padding: 4px 10px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mui.breakpoints.values.md + 1}px) {
    padding: 4px 20px;
  }

  border: 1px solid #b3b2ac;
  cursor: pointer;
  transition: ease background 0.2s;
  svg {
    margin: auto;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
    svg path {
      fill: white;
    }
  }
`

const StyledLinearProgress = styled(LinearProgress)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;
`

export default Header
