import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export default function BackgroundImage(props) {
  return (
    <Container {...props}>
      <BackgroundImageStyles
        width={props.width}
        height={props.height}
        minHeight={props.minHeight}
        maxHeight={props.maxHeight}
        size={props.size || "cover"}
        {...props}
      >
        {props.bg && (
          <GatsbyImage
            image={props.bg?.localFile?.childImageSharp?.gatsbyImageData}
            style={{
              objectFit: props.size || "cover",
              objectPosition: "50% 50%",
              fontFamily: `\"object-fit: ${
                props.size || "cover"
              }; object-position: 50% 50%\"`
            }}
            alt={props?.bg?.altText || ""}
          />
        )}
        {props.children}
      </BackgroundImageStyles>
    </Container>
  )
}

const Container = styled.div`
  position: ${(props) => (props.position ? props.position : "relative")};
  top: 0;
  height: ${(props) => (props.height ? props.height : "100%")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "auto")};
  width: ${(props) => (props.width ? props.width : "100%")};
  overflow: hidden;

  img {
    transition: ease all 0.5s !important;
  }

  &:hover {
    img {
      ${(props) => props.transition === "true" && "transform: scale(1.1);"}
      ${(props) => props.transition === "true" && "cursor: pointer;"}
    }
  }
`

const BackgroundImageStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;

  .gatsby-image-wrapper {
    height: 100% !important;
    width: 100% !important;
  }

  img {
    object-fit: ${(props) => props.size} !important;
    object-position: "50% 50%" !important;
    font-family: "object-fit: ${(props) =>
      props.size}; object-position: 50% 50%" !important;
  }
`
