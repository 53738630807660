import { graphql, useStaticQuery } from "gatsby"

const useFooterQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              email
              fieldGroupName
              orderNotificationsEmail
              addressLine2
              addressLine1
              phoneNumber {
                target
                title
                url
              }
              socials {
                fieldGroupName
                icon
                link {
                  url
                  title
                  target
                }
              }
              postalCode
            }
          }
        }
      }
    `
  )

  return wp
}

export default useFooterQuery
