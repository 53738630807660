const colors = {
  primary: "#213245",
  secondary: "#E21D38",
  darkRed: "#C51931",
  springWood: "#F7F7EF",
  greenWhite: "#EDEEE5",
  pickledBluewood: "#223244",
  watermelon: "#FF5F75"
}

export default colors
