import { useEffect, useState } from "react"

const useKeyPress = targetKey => {
  const [keyPressed, setKeyPressed] = useState(false)

  // If pressed key is our target key then set to true
  const downHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keydown", downHandler)
      window.addEventListener("keyup", upHandler)
      return () => {
        window.removeEventListener("keydown", downHandler)
        window.removeEventListener("keyup", upHandler)
      }
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount
  return keyPressed
}

export default useKeyPress
