import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"
import Link from "../link"
import Parser from "html-react-parser"
import useMenuItems from "./useMenuItems"

// import material ui
import Typography from "@material-ui/core/Typography"

// import app components
import * as theme from "../../theme"

const UtilityMenu = (props) => {
  const items = useMenuItems("utility-menu")

  return (
    <Menu {...props}>
      {items &&
        items.map(({ url, label }) => (
          <MenuItem key={url}>
            <MenuLink to={url}>
              <Typography
                color="inherit"
                component="div"
                children={Parser(label)}
              />
            </MenuLink>
          </MenuItem>
        ))}
    </Menu>
  )
}

export default UtilityMenu

const Menu = styled.div`
  display: flex;
  height: 32px;

  a {
    text-decoration: none;
  }
`
const MenuItem = styled.div`
  display: flex;
  position: relative;
  border: 1px solid #b3b2ac;
  transition: ease background 0.2s;
  color: ${theme.colors.primary};

  margin-right: 10px;
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.secondary};
    background: ${(props) => props.theme.colors.secondary};
    color: white;
  }
`

const MenuLink = styled(Link)`
  padding: 5px 20px 0;
  text-transform: uppercase;
  color: inherit;
  > div {
    font-family: "Amiri";
    font-size: 12px;
    line-height: 24px;
    font-weight: 700;
    height: 100%;
  }
`
