import produce from "immer"

export const userState = {
  userInfo: null,
  bookmarks: [],
  resources: []
}

export const userReducer = (state, action) => {
  const { payload } = action

  return produce(state, draft => {
    switch (action.type) {
      case "SET_USER_DATA":
        draft.bookmarks = payload.bookmarks
        draft.userInfo = payload.user
        break

      case "UPDATE_BOOKMARKS":
        draft.bookmarks = payload
        break

      case "SET_RESOURCES":
        draft.resources = payload
        break

      case "CLEAR_DATA":
        draft.bookmarks = []
        draft.resources = []
        break

      default:
    }
  })
}
