import React from "react"
import styled, { css } from "styled-components"

const YouTube = props => {
  const { video, ...rest } = props

  const videoId =
    !!video &&
    video.includes("youtu") &&
    video.substring(video.lastIndexOf("/") + 1)

  // console.log("videoId: ", videoId)
  return (
    <Container {...rest}>
      <Embed src={`https://www.youtube.com/embed/` + videoId} frameBorder={0} />
    </Container>
  )
}

export default YouTube

const Container = styled.div`
  position: relative;
  padding-bottom: 56.25% /* 16:9 */;
  /* padding-top: 25px; */
  height: 0;
  /* width: 1920px; */
  ${props =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 1920px;
        `}
  @media (min-width: 1300px) {
    max-width: 100%;
  }
  max-width: 98vw;
  background: black;
`
const Embed = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
